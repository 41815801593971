<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && !disabled  && updateMode"
                  :url="saveUrl"
                  :isSubmit="isComplete"
                  :param="popupParam.data.safetyCheckId ? 
                  { 
                    safetyCheckResultModelList: popupParam.data.safetyCheckResultModelList,
                    detailModel: detailData,
                    fileList: fileList
                  } : postSaveData"
                  :mappingType="mappingType"
                  label="완료"
                  icon="check"
                  @beforeAction="completeData"
                  @btnCallback="completeCallback" />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="popupParam.data.safetyCheckId ? 
                  { 
                    safetyCheckResultModelList: popupParam.data.safetyCheckResultModelList,
                    detailModel: detailData,
                    fileList: fileList
                  } : postSaveData"
                  :mappingType="mappingType"
                  label="일괄저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn v-if="editable && !disabled && updateMode" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  :required="true"
                  :disabled="disabled"
                  :changeItem="changeItem"
                  :editable="editable"
                  label="점검업체"
                  name="vendorCd"
                  v-model="detailData.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-process
                  :disabled="disabled"
                  multiple="multiple"
                  :editable="editable"
                  :plantCd="popupParam.data.plantCd"
                  label="공종"
                  name="processCd"
                  v-model="detailData.processCd"
                  @processName="getProcessName">
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :disabled="disabled"
                  :editable="editable"
                  label="작업"
                  name="sopName"
                  v-model="detailData.sopName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-btn 
                    icon="search"
                    :label="locationNames"
                    @btnClicked="srcClick" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :afterIcon="
                    editable && !disabled
                      ? [
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchUser',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeUser',
                          },
                        ]
                      : null
                  "
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :readonly="true"
                  label="점검자"
                  name="checkUserName"
                  v-model="detailData.checkUserName"
                  @searchUser="searchUser"
                  @removeUser="removeUser"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <c-textarea
                  :disabled="disabled"
                  :editable="editable"
                  label="점검자 의견"
                  name="checkOpinion"
                  v-model="detailData.checkOpinion">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :disabled="disabled"
                  :editable="editable"
                  label="부적합 사항"
                  name="conforContent"
                  v-model="detailData.conforContent">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :disabled="disabled"
                  :editable="editable"
                  label="즉시조치 사항"
                  name="actionContent"
                  v-model="detailData.actionContent">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            ref="table"
            title= "일별 안전점검 결과 목록"
            tableId="facilitySelfCheck"
            gridHeight="auto"
            :columns="grid.columns"
            :data="grid.data"
            :hideBottom="true"
            :isExcelDown="false"
            :editable="editable && !disabled"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
          >
            <template slot="suffixTitle">
              <font v-if="!popupParam.data.plantCd || !popupParam.data.checkMonth" color="#C10015">
                ※ 공사현장 및 점검년월을 선택하세요.
              </font>
            </template>
            <!-- 버튼 영역 -->
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
                <q-chip
                  v-for="(item, index) in $comm.inspectIbmTagItems(props.row, colorItems, popupParam.col.key)"
                  :key="index"
                  :color="item.color"
                  :clickable="true"
                  text-color="white"
                  style="margin-bottom:4px !important;"
                  @click="linkClick(item, props.row)">
                  {{item.title}}
                  <c-assess-tooltip 
                    :ibmTooltip="item.ibmTooltip"
                    :ibmClassCd="item.ibmClassCd"
                  />
                </q-chip>
              </template>
              <div v-if="popupParam.data.safetyCheckId && !disabled && col.name === 'customCol' && detailData.safetyCheckResultDetailId">
                <q-btn-group outline class="ColumInnerBtnGroup">
                  <q-btn
                    v-if="editable && !disabled"
                    icon="add"
                    color="orange-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props, '1')">
                    <q-tooltip>
                      개선요청등록
                    </q-tooltip>
                  </q-btn>
                  <q-btn
                    icon="add"
                    color="red-6"
                    text-color="white"
                    class="ColumInnerBtn"
                    align="center"
                    @click.prevent="innerBtnClicked(col, props, '2')">
                    <q-tooltip>
                      즉시조치등록
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </div>
            </template>
          </c-table>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="padding-top:30px !important">
          <c-upload 
            ref="file"
            :attachInfo="attachInfo"
            :editable="!disabled"
            label="관련 파일">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-check-checking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        col: {},
        data: {
          safetyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkMonth: '',  // 점검년월
          vendorCd: '',
          safetyCheckResultModelList: [], // sheet
        }
      }),
    },
    returnData: {
      type: Object,
      default: () => ({
        col1: null,
        col2: null,
        col3: null,
      }),
    }
  },
  data() {
    return {
      locationNames: '위치',
      year: '',
      grid: {
        columns: [],
        data: [],
      },
      detailData: {
        safetyCheckId: '',  // 안전점검 일련 번호
        dayId: '',
        safetyCheckResultDetailId: '',  // 안전점검결과 일련 번호
        checkUserId: '',  // 점검자 ID
        checkUserName: '',
        checkDate: '',  // 점검일
        safetyCheckName: '',  // 점검명
        conforContent: '',  // 부적합사항
        actionContent: '',  // 즉시조치사항
        vendorCd: '',
        processCd: '',
        processName: '',
        sopName: '',
        sopMapId: '',
        location: '',
        checkOpinion: '',  // 점검자의견
        completeFlag: 'N',
        maps: [],
        deletemaps: [],
      },
      changeItem: {
        plant: '',
        vendor: '',
      },
      postSaveData: {
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkMonth: '',  // 점검년월
        processCd: '',
        processName: '',
        sopName: '',
        location: '',
        fileList: [],
        safetyCheckResultModelList: [], // sheet
        maps: [],
        deletemaps: [],
        detailModel: {
          safetyCheckId: '',  // 안전점검 일련 번호
          dayId: '',
          safetyCheckResultDetailId: '',  // 안전점검결과 일련 번호
          checkUserId: '',  // 점검자 ID
          checkUserName: '',
          checkDate: '',  // 점검일
          safetyCheckName: '',  // 점검명
          conforContent: '',  // 부적합사항
          actionContent: '',  // 즉시조치사항
          checkOpinion: '',  // 점검자의견
          vendorCd: '',
          processCd: '',
          processName: '',
          sopName: '',
          sopMapId: '',
          location: '',
          completeFlag: 'N',
          maps: [],
          deletemaps: [],
        }
      },
      popupOptions: {
        isFull: false,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: transactionConfig.sai.safetycheck.detail.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      lunarHolidays: [],
      today: '',
      lineCd: '',
      checkUserId: '',
      checkboxItems: [],
      colorItems: [],
      fileList: [],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SAI_SAFETY_CHECK',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveState() {
     return Boolean(this.popupParam.data.safetyCheckId)
    },
    disabled() {
     return this.popupParam.data.checkStatusCd === 'MCSC000015' || this.popupParam.data.approvalStatusCd === 'ASC0000001' || this.detailData.completeFlag === 'Y'
    },
    vendor() {
      return this.$store.getters.user.innerFlag === 'N' ? this.$store.getters.user.deptCd :  '';
    },
    updateMode() {
      return Boolean(this.detailData.safetyCheckResultDetailId)
    },
    isVendor() {
      if (this.$store.getters.user.innerFlag === 'N') {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.colorItems = _result
      });
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting

      this.getUrl = selectConfig.sai.safetycheck.plan.get.url;
      this.getDetailUrl = selectConfig.sai.safetycheck.detail.get.url;
      this.insertUrl = transactionConfig.sai.safetycheck.detail.insert.url;
      this.resultItemDetail = selectConfig.sai.safetycheck.result.newItem.url;
      this.updateUrl = transactionConfig.sai.safetycheck.result.newItem.url;
      this.deleteUrl = transactionConfig.sai.safetycheck.detail.delete.url
      // code setting
      // list setting
      this.setHeader();
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.data.safetyCheckId) {
        this.$http.url = this.getDetailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          safetyCheckId: this.popupParam.data.safetyCheckId,
          dayId: this.popupParam.col.key
        };
        this.$http.request((_result) => {
          if (_result.data) {
            this.detailData = _result.data;
            this.$set(this.attachInfo, 'taskKey', this.detailData.safetyCheckResultDetailId)
            let chkmaplabel = false;
            this.$_.forEach(this.detailData.maps, _item => {
              if (_item.mapContent) {
                chkmaplabel = true;
              }
            })
            if (this.detailData.maps.length > 0) {
              if (chkmaplabel) {
                this.locationNames = '순찰위치 : ' + this.detailData.maps[0].mapContent;

                if (this.detailData.maps.length > 1)  {
                  this.locationNames = this.locationNames + ' 외' + (this.detailData.maps.length - 1) + '곳'
                }
              } else {
                this.locationNames = '순찰위치 : 선택됨';
              }
            }
          } else {
            if (this.popupParam.data.vendorCd) {
              this.detailData.vendorCd = this.popupParam.data.vendorCd;
            } else {
              this.detailData.vendorCd = this.$store.getters.user.selfVendorCd;
            }
            this.detailData.checkUserId = this.$store.getters.user.userId;
            this.detailData.checkUserName = this.$store.getters.user.userName;
          }
        },);
      }
    },
    setHeader() {
      this.grid.data = this.popupParam.data.safetyCheckResultModelList;
      this.$comm.getComboItems('SAFETY_CHECK_RESULT_CD').then(_result => {
        this.checkboxItems = _result;
        let _columns = [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
        ]
        let days = [];
        days.push(
          {
            required: true,
            name: this.popupParam.col.key,
            field: this.popupParam.col.key,
            label: this.popupParam.col.label + '일',
            align: 'center',
            setHeader: true,
            comboItems: _result,
            style: 'width:100px',
            type: 'select',
            sortable: false,
          // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
          },
        )
        this.grid.columns = this.$_.concat(_columns, days, 
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:350px',
                type: 'custom',
                sortable: false
              },
            ]
          },)
        if (!this.disabled) {
          this.$_.forEach(this.grid.data, _item => {
            if (!_item[this.popupParam.col.key]) {
              _item[this.popupParam.col.key] = 'SCR0000010'
              if (_item.editFlag !== 'C') {
              _item.editFlag = 'U'
              }
            }
          })
        }
      });
    },
    research() {
      this.$http.url = this.$format(this.getUrl, this.popupParam.data.safetyCheckId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data.safetyCheckResultModelList;
      },);
    },
    saveData() {
      if (this.popupParam.data.safetyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.popupParam.data.regUserId = this.$store.getters.user.userId;
                this.popupParam.data.chgUserId = this.$store.getters.user.userId;
                
                if (this.mappingType === 'POST') {
                  this.$set(this.postSaveData, 'plantCd', this.popupParam.data.plantCd)
                  this.$set(this.postSaveData, 'checkMonth', this.popupParam.data.checkMonth)
                  this.$set(this.postSaveData, 'processCd', this.detailData.processCd)
                  this.$set(this.postSaveData, 'vendorCd', this.detailData.vendorCd)
                  this.$set(this.postSaveData, 'processName', this.detailData.processName)
                  this.$set(this.postSaveData, 'sopName', this.detailData.sopName)
                  this.$set(this.postSaveData, 'sopMapId', this.detailData.sopMapId)
                  this.$set(this.postSaveData, 'safetyCheckName', this.popupParam.data.safetyCheckName)
                  this.$set(this.postSaveData, 'safetyCheckResultModelList', this.popupParam.data.safetyCheckResultModelList)
                  this.$set(this.postSaveData, 'maps', this.detailData.maps)
                  this.$set(this.postSaveData, 'deleteMaps', this.detailData.deleteMaps)
                  this.$set(this.postSaveData, 'detailModel', this.detailData)
                  this.$set(this.postSaveData, 'companyCd', this.$store.getters.user.companyCd)
                  this.$set(this.postSaveData, 'fileList', this.$refs['file'].fileList)
                } else {
                  this.fileList = this.$refs['file'].fileList;
                }

                this.popupParam.data.safetyCheckResultModelList = this.$_.clone(this.grid.data);

                this.$set(this.detailData, 'safetyCheckId', this.popupParam.data.safetyCheckId)
                this.$set(this.detailData, 'dayId', this.popupParam.col.key)
                this.$set(this.detailData, 'checkDate', this.popupParam.col.day)

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } 
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      })
    },
    saveCallback(result) {
      if (this.mappingType === 'POST') {
        this.popupParam.data.safetyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.safetyCheckId = result.data;
          _item.editFlag = 'U';
        })
      }

      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getDetail();
    },
    completeData() {
      if (this.popupParam.data.safetyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.popupParam.data.regUserId = this.$store.getters.user.userId;
                this.popupParam.data.chgUserId = this.$store.getters.user.userId;
                
                if (this.mappingType === 'POST') {
                  this.set(this.postSaveData, 'plantCd', this.popupParam.data.plantCd)
                  this.set(this.postSaveData, 'checkMonth', this.popupParam.data.checkMonth)
                  this.set(this.postSaveData, 'processCd', this.detailData.processCd)
                  this.set(this.postSaveData, 'processName', this.detailData.processName)
                  this.set(this.postSaveData, 'sopName', this.detailData.sopName)
                  this.set(this.postSaveData, 'sopMapId', this.detailData.sopMapId)
                  this.set(this.postSaveData, 'safetyCheckName', this.popupParam.data.safetyCheckName)
                  this.set(this.postSaveData, 'maps', this.detailData.maps)
                  this.set(this.postSaveData, 'deleteMaps', this.detailData.deleteMaps)
                  this.set(this.postSaveData, 'detailModel', this.detailData)
                  this.$set(this.postSaveData, 'companyCd', this.$store.getters.user.companyCd)
                } else {
                  this.fileList = this.$refs['file'].fileList;
                }

                this.popupParam.data.safetyCheckResultModelList = this.$_.clone(this.grid.data);

                this.$set(this.detailData, 'completeFlag', 'Y')
                this.$set(this.detailData, 'safetyCheckId', this.popupParam.data.safetyCheckId)
                this.$set(this.detailData, 'dayId', this.popupParam.col.key)
                this.$set(this.detailData, 'checkDate', this.popupParam.col.day)

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } 
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      })
    },
    completeCallback(result) {
      if (this.mappingType === 'POST') {
        this.popupParam.data.safetyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.safetyCheckId = result.data;
          _item.editFlag = 'U';
        })
      }

      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getDetail();
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          plantCd: this.popupParam.data.plantCd,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.popupParam.col.key,
          ibmTaskTypeCd: 'ITT0000001',
          sopMapId: this.detailData.sopMapId,
          row: this.detailData,
          ibmTitle: this.detailData.processName + '/' + this.detailData.sopName,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: props.row.checkItemName,
          plantCd: this.popupParam.data.plantCd,
          relationTableKey: props.row.safetyCheckResultId+"/"+this.popupParam.col.key,
          ibmTaskTypeCd: 'ITT0000001',
          sopMapId: this.detailData.sopMapId,
          row: this.detailData,
          ibmTitle: this.detailData.processName + '/' + this.detailData.sopName,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.popupParam.data.safetyCheckResultModelList = this.$_.clone(this.grid.data);

        this.$http.url = transactionConfig.sai.safetycheck.result.newItem.url;
        this.$http.type = 'PUT';
        this.$http.param = {
          safetyCheckResultModelList: this.$_.clone(this.grid.data),
          detailModel: this.detailData,
        }
        this.$http.request(() => {
          this.research();
          this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        },);
        // this.$_.forEach(data, item => {
        //   let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
        //   if (sData.indexOf(item.sopImprovementId) === -1) {
        //     this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
        //       this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
        //     this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
        //       this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
        //     this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
        //       this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
        //     this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
        //       this.requestImprRow.ibmTooltip + ',' + item.ibmTooltip : item.ibmTooltip
        //   }
        // })
        // this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    // result, flag
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.$http.url = transactionConfig.sai.safetycheck.result.newItem.url;
      this.$http.type = 'PUT';
      this.$http.param = {
        safetyCheckResultModelList: this.$_.clone(this.grid.data),
        detailModel: this.detailData,
      }
      this.$http.request(() => {
        this.research();
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      },);
      // if (result && result.col1) {
      //   if (flag !== 'delete') {
      //     let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
      //     let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
      //     if (splitId && splitId.length > 0) {
      //       let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
      //       splitStep[idIndex] = result.col1.ibmStepName;
      //       this.requestImprRow.ibmStepNames = splitStep.toString();
      //     }
      //   } else {
      //     let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
      //     let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
      //     let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
      //     if (splitId && splitId.length > 0) {
      //       let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
      //       splitId.splice(idIndex, 1)
      //       splitStep.splice(idIndex, 1)
      //       splitClass.splice(idIndex, 1)
      //       this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
      //       this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
      //       this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
      //     }
      //   }
      //   this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      // }
    },
    getProcessName(data) {
      this.$set(this.detailData, 'processName', data)
    },
    srcClick() {
      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        plantCd: this.popupParam.data.plantCd,
        safetyCheckId: this.popupParam.data.safetyCheckId,
        maps: this.detailData.maps ? this.detailData.maps : [],
        deleteMaps: this.detailData.deleteMaps ? this.detailData.deleteMaps : [],
        sopMapId: this.detailData.sopMapId,
        disabled: this.disabled,
      };
      this.popupOptions.target = () => import(`${'./safetyCheckMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.detailData.maps = data.maps;
        this.detailData.deleteMaps = data.deleteMaps;
        this.detailData.sopMapId = data.sopMapId;
      }
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.grid.data, _item => {
            _item[this.popupParam.col.key] = null;
            _item.editFlag = 'U'
          })
          this.$http.url = this.deleteUrl;
          this.$http.type = 'PUT';
          this.$http.param = {
            safetyCheckResultModelList: this.$_.clone(this.grid.data),
            safetyCheckResultDetailId: this.detailData.safetyCheckResultDetailId,
            dayId: this.popupParam.col.key,
          }
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.returnData.col1 = this.popupParam.data.safetyCheckId;
            this.$emit('closePopup', this.returnData);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    searchUser() {
      this.editing = false;
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        isAllVendor: true,
        isSelf: this.isSelf,
        isVendorOrg: true,
        type: 'multiple',
        plantCd: this.popupParam.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.map(data, 'userId').forEach((item, idx) => {
          if (this.detailData.checkUserId) {
            let arrayIdx = this.detailData.checkUserId.split(',').indexOf(item);
            if (arrayIdx === -1) {
              this.detailData.checkUserId += ',' + this.$_.map(data, 'userId')[idx];
              this.detailData.checkUserName += ',' + this.$_.map(data, 'userName')[idx];
            }
          } else {
            this.detailData.checkUserId = this.$_.map(data, 'userId').join(',')
            this.detailData.checkUserName = this.$_.map(data, 'userName').join(',')
          }
        })
      }
    },
    removeUser() {
      this.detailData.checkUserId = '';
      this.detailData.checkUserName = ''
    }
  }
};
</script>

<style lang="sass">
.safetyCheckFieldSelect
  .customDate
    padding-bottom: 0px !important
    .q-field__before
      padding-top: 5px
  .custom-text-before
    font-size:14px
    border: 1px solid rgba(0, 0, 0, 0.15) !important
    border-right: 0  !important
    border-radius: 4px 0 0 0
    line-height: 31px
    height: 33px !important
    min-height: 33px !important
  .custom-field
    .q-field__native
      cursor: pointer
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important
.locationNames
  margin-left: 10px
  </style>
